import React from "react";
import { InfoWindow } from "react-google-maps";

const InfoWindowComponent = (props) => {
  return (
    <InfoWindow onCloseClick={props.toggleClose} options={{ maxWidth: 320 }}>
      <div>
        <h3>Restaurant Alegria Castanilor</h3>
        <br />
        <a href="https://www.waze.com/ul?ll=44.5070447-25.9815095&navigate=yes&zoom=17">Castanilor 4A, Chitila, Romania</a>
      </div>
    </InfoWindow>
  );
};

export default InfoWindowComponent;