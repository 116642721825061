import React, { Component } from "react";
import MapComponent from "./MapComponent";


class Harta extends Component {

  constructor() {
    super();
    this.state = {
      center: { lat: 44.5070447, lng: 25.9815095 },
      zoom: 15
    };
  }

  render() {
    let apiKey = "AIzaSyACnPmzL9y9qmAuYbeAJaYMBCh7BJCfkTY";
    
    return (
      <div className="App">
        <MapComponent
          center = {this.state.center}
          zoom = {this.state.zoom}
          defaultZoom={this.state.zoom}
          googleMapURL={`https://maps.googleapis.com/maps/api/js?key=${apiKey}&v=3.exp&libraries=geometry,drawing,places`}
          loadingElement={<div style={{ height: `100%` }} />}
          containerElement={<div style={{ height: `500px` }} />}
          mapElement={<div style={{ height: `100%` }} />}
        />
      </div>
    );
  }
}

export default Harta;